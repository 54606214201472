import type { RouteRecordRaw } from "vue-router"
import { ProtectedRoutes } from "../IRoute"
import { featureCheck, Features } from "@/common/util/featuresUtil"

const providerRoutes: RouteRecordRaw[] = [
  {
    path: "/provider",
    name: "provider",
    component: () => import("@/layout/AbstractRouteView.vue"),
    meta: {
      isProtected: true
    },
    children: [
      {
        path: ProtectedRoutes.provider.configure.path,
        name: ProtectedRoutes.provider.configure.pathName,
        component: () => import("@/modules/create-offer/pages/ConfigureOffer.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Provide",
              translationKey: "sideMenu.provide"
            },
            {
              title: "Provide data",
              translationKey: "sideMenu.publishData",
              routeName: ""
            }
          ]
        }
      },
      {
        path: ProtectedRoutes.provider.uploadHistory.path,
        name: ProtectedRoutes.provider.uploadHistory.pathName,
        component: () => import("@/modules/provide/upload-history/pages/UploadHistory.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Provide",
              translationKey: "sideMenu.provide"
            },
            {
              title: "Asset upload history",
              translationKey: "sideMenu.uploadHistory",
              routeName: ""
            }
          ]
        }
      },
      {
        path: ProtectedRoutes.provider.digitalTwin.path,
        name: ProtectedRoutes.provider.digitalTwin.pathName,
        component: () =>
          featureCheck([Features.DIGITAL_TWIN_BROWSER])
            ? import("@/modules/provide/digital-twin-browser/pages/DigitalTwinBrowser.vue")
            : import("@/modules/error/PageNotFound.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Provide",
              translationKey: "sideMenu.provide"
            },
            {
              title: "Digital Twin Browser",
              translationKey: "sideMenu.digitalTwin",
              routeName: ""
            }
          ]
        }
      }
    ]
  }
]

export default providerRoutes
